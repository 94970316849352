import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import {
  AppealSectionForLp,
  CommitmentToSecuritySectionForLp,
  CompanyCasesSectionForLp,
  EyeCatchForLp,
  FeatureSectionForLp,
  FixedFooterForLp,
  FooterForLp,
  HeaderForLp,
  IntroductionFlowSectionForLp,
  LogoSectionForLp,
  PriceSectionForLp,
  QuestionSectionForLp,
  ResolveSectionForLp
} from "components/pages/lp"
import { NextHead } from "components/shared/NextHead"
import { Entry, EntryCollection } from "contentful"
import { DESCRIPTION_FOR_LP, IMAGE_FOR_LP, TITLE_FOR_LP } from "helpers/ogp"
import { buildClient, getCredentialsFromEnv, IArticle } from "libs/contentful"
import { GetStaticProps, InferGetStaticPropsType, NextPage } from "next"

export const getStaticProps: GetStaticProps<{articles: Entry<IArticle>[]}> = async () => {
  const { space, accessToken, environment } = getCredentialsFromEnv()

  if (!space || !accessToken) {
    return { props: { articles: [] } }
  }

  const client = buildClient({ space, accessToken, environment })

  const { items: articles }: EntryCollection<IArticle> = await client.getEntries({
    content_type: "company_case",
    limit: 3
  })
  return {
    props: { articles },
  }
}

type Props = InferGetStaticPropsType<typeof getStaticProps>;

const Page: NextPage<Props> & { getHead: ()=> JSX.Element } = ({ articles }) => {
  const { xs } = useBreakpoint()

  return (
    <>
      <HeaderForLp />
      <EyeCatchForLp />
      <LogoSectionForLp />
      <ResolveSectionForLp />
      <FeatureSectionForLp />
      <AppealSectionForLp />
      <CompanyCasesSectionForLp articles={articles}/>
      <PriceSectionForLp />
      <CommitmentToSecuritySectionForLp />
      <AppealSectionForLp />
      <IntroductionFlowSectionForLp />
      {xs && <AppealSectionForLp />}
      <QuestionSectionForLp />
      <AppealSectionForLp />
      <FooterForLp />
      {xs && <FixedFooterForLp />}
    </>
  )
}

Page.getHead = () => (
  <NextHead
    title={TITLE_FOR_LP}
    description={DESCRIPTION_FOR_LP}
    thumbnailUrl={IMAGE_FOR_LP}
  />
)
export default Page
